import React from "react";

import Layout from "../components/layout";
import CtaSection from "../components/CtaSection";

import accountActivationEmailImage from "../images/email-templates/email-template-account-activation.jpg";
import welcomeEmailImage from "../images/email-templates/email-template-welcome.jpg";
import passwordResetEmailImage from "../images/email-templates/email-template-password-reset.jpg";
import ssoEmailImage from "../images/email-templates/email-template-sso.jpg";
import receiptEmailImage from "../images/email-templates/email-template-receipt.jpg";
import paymentFailedEmailImage from "../images/email-templates/email-template-payment-failed.jpg";
import canceledsubscriptionEmailImage from "../images/email-templates/email-template-canceled-subscription.jpg";
import trialExpiredEmailImage from "../images/email-templates/email-template-trial-ended.jpg";
import invoiceEmailImage from "../images/email-templates/email-template-invoice.jpg";
import organizationInviteEmailImage from "../images/email-templates/email-template-organization-invite.jpg";
import newOrganizationMemberEmailImage from "../images/email-templates/email-template-new-organization-member.jpg";
import loginAlertEmailImage from "../images/email-templates/email-template-login-alert.jpg";
import newMessageNotificationEmailImage from "../images/email-templates/email-template-new-message-notification.jpg";
import verificationCodeEmailImage from "../images/email-templates/email-template-verification-code.jpg";

export default () => {
	const EmailTemplateContainer = ({ title, href, src, description }) => {
		return (
			<a
				className="emailTemplateContainer box-whiteWithBorder mb-20"
				href={href}>
				<div className="emailTemplateImageWrapper">
					<img
						src={src}
						loading="lazy"
						alt={title}
						className="emailTemplateImage"
					/>
				</div>
				<div className="emailTemplateText">
					<h2 className="">{title}</h2>

					<div className="emailTemplateDescription text-small text-mediumDark verticalList mb-15">
						{description}
					</div>
				</div>
			</a>
		);
	};

	return (
		<Layout
			pageMeta={{ title: "Transactional email templates", description: "" }}>
			<div>
				<section className="aboveFold">
					<div className="container">
						<h2 className="text-xsmall uptitle uptitle--new m-0">
							Dynamic email templates
						</h2>

						<h1>Transactional email templates</h1>

						<p className="maxWidth-750 itemCenter text-lead">
							Sidemail provides you with transactional email templates for any
							scenario and functionality to let you hit the ground running — in
							minutes. All templates are well‑tested and optimized to look great
							in any inbox. Just select the templates you need and start
							sending.
						</p>
					</div>
				</section>

				<section className="container maxWidth-820 mb-150">
					<EmailTemplateContainer
						title="Password reset email"
						href="/transactional-email-templates/password-reset-email-template/"
						src={passwordResetEmailImage}
						description="Deliver password reset email in case users need to change their password."
					/>

					<EmailTemplateContainer
						title="Welcome email"
						href="/transactional-email-templates/welcome-email-template/"
						src={welcomeEmailImage}
						description="Send welcome emails to greet users in your application or service, guide them through their first steps and offer help if needed."
					/>

					<EmailTemplateContainer
						title="Account activation email"
						href="/transactional-email-templates/account-activation-email-template/"
						src={accountActivationEmailImage}
						description="Send account activation email to verify your users' email addresses and activate their accounts."
					/>

					<EmailTemplateContainer
						title="Single sign-on email (SSO)"
						href="/transactional-email-templates/sso-email-template/"
						src={ssoEmailImage}
						description="Offer passwordless login and send SSO emails."
					/>

					<EmailTemplateContainer
						title="Receipt email"
						href="/transactional-email-templates/receipt-email-template/"
						src={receiptEmailImage}
						description="Send your user an email with a receipt, invoice, order confirmation, or subscription activation. "
					/>

					<EmailTemplateContainer
						title="Subscription payment failed email"
						href="/transactional-email-templates/payment-failed-email-template/"
						src={paymentFailedEmailImage}
						description="Notify users about issues with payment."
					/>

					<EmailTemplateContainer
						title="Invoice email"
						href="/transactional-email-templates/invoice-email-template/"
						src={invoiceEmailImage}
						description="Send an invoice email summarizing charges, include a payment button for swift settlement, and provide an option to download the PDF."
					/>

					<EmailTemplateContainer
						title="Trial expired email"
						href="/transactional-email-templates/trial-expired-email-template/"
						src={trialExpiredEmailImage}
						description="Send notification email when trial is ending and increase conversion rates."
					/>

					<EmailTemplateContainer
						title="New message notification email"
						href="/transactional-email-templates/new-message-notification-email-template/"
						src={newMessageNotificationEmailImage}
						description="Send a prompt email alert whenever a user receives a new message or comment, ensuring timely responses and better engagement."
					/>

					<EmailTemplateContainer
						title="Verification code email"
						href="/transactional-email-templates/verification-code-email-template/"
						src={verificationCodeEmailImage}
						description="Email a one-time verification code to users, helping them quickly verify their identity or authorize actions and keeping their accounts secure."
					/>

					<EmailTemplateContainer
						title="Canceled subscription email"
						href="/transactional-email-templates/canceled-subscription-email-template/"
						src={canceledsubscriptionEmailImage}
						description="Send follow up email on canceled subscription."
					/>

					<EmailTemplateContainer
						title="Organization invite email"
						href="/transactional-email-templates/organization-invite-email-template/"
						src={organizationInviteEmailImage}
						description="Invite team members to join your organization, set up their account, and start collaborating."
					/>

					<EmailTemplateContainer
						title="New organization member joined email"
						href="/transactional-email-templates/new-organization-member-email-template/"
						src={newOrganizationMemberEmailImage}
						description="Send a notification whenever new members join your organization, so you can keep your team in sync and foster collaboration."
					/>

					<EmailTemplateContainer
						title="Login alert email"
						href="/transactional-email-templates/login-alert-email-template/"
						src={loginAlertEmailImage}
						description="Deliver a notification whenever a new or suspicious login is detected, helping your users stay on top of account security."
					/>
				</section>

				<section className="container maxWidth-820 text-center mb-150">
					<h2 className="title-large">Didn't find the right email template?</h2>

					<p className="mb-40">
						Sidemail features{" "}
						<a href="/no-code-email-editor/"> No-code email editor</a> – the
						simplest way to craft professionally‑looking, responsive email
						templates that won't break. Combine it with Sidemail's AI email
						generator, which will help you craft the email copy, and you're
						ready to start sending in style within a few moments.
					</p>

					<div className="videoContainer">
						<video loop autoPlay playsInline muted height={300}>
							<source src="/assets/editor.mp4" type="video/mp4" />
						</video>
					</div>
				</section>

				<CtaSection />
			</div>
		</Layout>
	);
};
